import React, { Component } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import LinkButton from "../../components/LinkButton/LinkButton";

const INITIAL_STATE = {
  items: [],
  loading: false,
};

class ProjectBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  componentDidMount() {
    this.setState({ loading: true });
    const items = [];
    fetch("http://povosapi-env-1.eba-g5qfshtz.us-east-2.elasticbeanstalk.com/v1/project")
      .then((res) => res.json())
      .then(
        (result) => {
          result.forEach((doc) => {
           
            const date = new Date(0);
            date.setUTCSeconds(doc.startDate);
            let a = [
              { day: "numeric" },
              { month: "short" },
              { year: "numeric" },
            ];
            let s = this.join(date, a, "-");

            items.push({
              id: doc.id,
              name: doc.name,
              description: doc.description,
              startDate: s,
              type: doc.type.name,
            });
          });
          this.setState({
            items: items,
            loading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            error,
          });
        }
      );
  }

  join(t, a, s) {
    function format(m) {
      let f = new Intl.DateTimeFormat("en", m);
      return f.format(t);
    }
    return a.map(format).join(s);
  }
  render() {
    const { items, loading } = this.state;
    return (
      <div className="container">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-actions float-right"></div>
                  <h4 className="card-title mb-0">List of projects</h4>
                </div>
                <div className="card-body">
                  <Loader loading={loading} />
                  {false && (
                    <Container>
                      <Row>
                        <Col xs={10}></Col>
                        <Col>
                          <LinkButton to="/admin/users/create">
                            Create User
                          </LinkButton>
                        </Col>
                      </Row>
                    </Container>
                  )}
                  <Container>
                    <Row>
                      <Col>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Description</th>
                              <th>Type</th>
                              <th>Start date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              items.map((item) => (
                              <tr key={item.id}>
                                <td>{item.name}</td>
                                <td>{item.description}</td>
                                <td>{item.type}</td>
                                <td>{item.startDate}</td>
                                <td></td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectBase;
