import React, { Component } from "react";

const INITIAL_STATE = {
  id: "ES-EN",
  name: ""
};

class ProjectTypeBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
}

export default ProjectTypeBase;
