import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Route } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import * as ROUTES from "../../constants/routes";
import ProjectPage from "../../pages/ProjectPage";
import DashboardPage from "../../pages/DashboardPage";
import TypePage from "../../pages/ProjectTypePage";

const Navigation = ({ authUser }) => (
  <div>
    <NavigationNonAuth />
    <Route exact path={ROUTES.DASHBOARD} component={DashboardPage} />
    <Route exact path={ROUTES.PROJECT} component={ProjectPage} />
  </div>
);

const NavigationNonAuth = () => (
  <Navbar>
    <Nav.Item>
      <LinkContainer to={ROUTES.TYPE}>
        <Nav.Link>Type</Nav.Link>
      </LinkContainer>
    </Nav.Item>
    <Nav.Item>
      <LinkContainer to={ROUTES.PROJECT}>
        <Nav.Link>Project</Nav.Link>
      </LinkContainer>
    </Nav.Item>
  </Navbar>
);

export default Navigation;
